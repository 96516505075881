<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn && !dialog"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getTraining" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>
				
				<v-col cols="auto" class="text-right py-0 mb-1">
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverNew? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverNew = !hoverNew"
									@mouseleave="hoverNew = !hoverNew"
									@click="newItem"
								>mdi-plus-circle</v-icon>
							</template>
							<small>New</small>
						</v-tooltip>
					</template>
					
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverRefresh = !hoverRefresh"
									@mouseleave="hoverRefresh = !hoverRefresh"
									@click="getTraining"
								>mdi-refresh-circle</v-icon>
							</template>
							<small>Refresh</small>
						</v-tooltip>
					</template>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0 pr-1">
			<v-data-table
				:dense="$vuetify.breakpoint.lgAndDown"
				fixed-header
				:headers="headers"
				:height="tableHeight"
				:items="training"
				:loading="dataTableLoading"
				loading-text="Loading... Please wait"
				no-data-text="No records found."
				:options.sync="tableOptions"
				:page="tableOptions.page"
				:server-items-length="tableTotal"
				ref="dataTableTraining"
				@update:items-per-page="tableResize; getTraining();"
				@update:page="getTraining"
				@update:sort-desc="getTraining"
			>
				<template v-slot:header.TRNG_NAME="{ header }">
					{{ header.text }}<br/>DOCUMENT NUMBER
				</template>
				
				<template v-slot:item.TRNG_NAME="{ item }" >
					<div style="min-height:20px;">{{ item.TRNG_NAME }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.TRNG_CERTNUMB }}</div>
				</template>
				
				<template v-slot:header.TRNG_DATETAKEN="{ header }">
					{{ header.text }}<br/>DATE FINISHED
				</template>
				
				<template v-slot:item.TRNG_DATETAKEN="{ item }" >
					<div style="min-height:20px;">{{ item.TRNG_DATETAKEN }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.TRNG_DATE_FIN }}</div>
				</template>
				
				<template v-slot:header.TRNG_DATEISSUED="{ header }">
					{{ header.text }}<br/>DATE OF EXPIRY
				</template>
				
				<template v-slot:item.TRNG_DATEISSUED="{ item }" >
					<div style="min-height:20px;">{{ item.TRNG_DATEISSUED }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.TRNG_DATE_VALID }}</div>
				</template>
				
				<template v-slot:item.status="{ item }" >
					<v-chip v-if="itemStatus(item.TRNG_DATE_VALID) < 1" color="error" dark small>EXPIRED</v-chip>
					<v-chip v-else-if="itemStatus(item.TRNG_DATE_VALID) < 90" color="warning" dark small>FOR RENEWAL</v-chip>
					<v-chip v-else color="success" dark small>VALID</v-chip>
				</template>
				
				<template v-slot:item.action="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="success" @click="editItem(item)">mdi-pencil</v-icon>
						</template>
						<small>Edit</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="error" @click="dialogDelete = true;deleteIndex = item">mdi-delete</v-icon>
						</template>
						<small>Delete</small>
					</v-tooltip>
				</template>
			</v-data-table>
			
			<v-dialog
				v-model="dialog"
				:fullscreen="$vuetify.breakpoint.xsOnly && !dialogLoading"
				max-width="650"
				:persistent="dialogLoading"
				scrollable
				@click:outside="resetFormValidation();"
				@keydown.enter="saveItem"
			>
				<v-card v-if="!dialogLoading">
					<v-card-title class="headline primary white--text" primary-title>
						{{ dialogTitle }}
						
						<v-spacer></v-spacer>
							
						<v-btn icon
							@click="$refs.form.resetValidation(); dialog = false;"
							class="white--text"
						>
							<v-icon small dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					
					<v-card-text>
						<v-form
							ref="form"
							v-model="validForm"
							lazy-validation
						>
							<v-row dense no-gutters>
								<v-col cols="12">
									<v-alert
										dense
										outlined
										border="left"
										:value="errorOn && dialog"
										:type="errorType"
										class="mt-2 py-1"
									>
										{{ errorMessage }}
									</v-alert>
								</v-col>
								
								<v-col cols="12">
									<v-autocomplete
										v-if="!newTraining"
										label="Training Name*"
										v-model="editedItem.TRNG_ID"
										clearable
										:items="trainingOption"
										item-text="TRNG_NAME"
										item-value="TRNG_ID"
										:rules= "rulesTrainingName"
										validate-on-blur
										:loading="isSearching"
										:search-input.sync="searchTraining"
										:no-data-text="trainingNoDataText"
										:allow-overflow=false
										@focus="trainingOn = true"
										@blur="trainingOn = false"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newTraining = !newTraining">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
										
									</v-autocomplete>
									
									<v-text-field
										v-if="newTraining"
										v-model="editedItem.TRNG_NAME"
										label="Training Name*"
										hint="Specify here"
										persistent-hint
										:rules= "rulesTrainingName"
										validate-on-blur
										@blur="editedItem.TRNG_NAME = trimBlur(editedItem.TRNG_NAME)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newTraining = !newTraining">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										v-model="editedItem.TRNG_CERTNUMB"
										label="Document Number*"
										:rules= "rulesTrainingNumber"
										validate-on-blur
										@blur="editedItem.TRNG_CERTNUMB = trimBlur(editedItem.TRNG_CERTNUMB)"
										class="uppercase-input"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-autocomplete
										v-if="!newTrainingCenter"
										label="Training Center*"
										v-model="editedItem.TRNG_CENTER_ID"
										clearable
										:items="trainingCenterOption"
										item-text="NAME"
										item-value="ID"
										:rules= "rulesTrainingCenter"
										validate-on-blur
										:loading="searchingTrainingCenter"
										:search-input.sync="searchTrainingCenter"
										:no-data-text="trainingCenterNoDataText"
										:allow-overflow=false
										@focus="trainingCenterOn = true"
										@blur="trainingCenterOn = false"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newTrainingCenter = !newTrainingCenter">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
										
									</v-autocomplete>
									
									<v-text-field
										v-if="newTrainingCenter"
										v-model="editedItem.TRNG_CENTER"
										label="Training Center*"
										hint="Specify here"
										persistent-hint
										:rules= "rulesTrainingCenterNew"
										validate-on-blur
										@blur="editedItem.TRNG_CENTER = trimBlur(editedItem.TRNG_CENTER)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newTrainingCenter = !newTrainingCenter">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Date Taken*"
										:rules="rulesTaken"
										validate-on-blur
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DateTakenType"
										v-model="editedItem.TRNG_DATETAKEN"
										@focus="DateTakenType = 'date';"
										@blur="DateTakenType = (isValidDate(editedItem.TRNG_DATETAKEN))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Date Finished*"
										:rules="rulesFinished"
										validate-on-blur
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DateFinishedType"
										v-model="editedItem.TRNG_DATE_FIN"
										@focus="DateFinishedType = 'date';"
										@blur="DateFinishedType = (isValidDate(editedItem.TRNG_DATE_FIN))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Date Issued*"
										:rules= "rulesIssued"
										validate-on-blur
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DateIssuedType"
										v-model="editedItem.TRNG_DATEISSUED"
										@focus="DateIssuedType = 'date';"
										@blur="DateIssuedType = (isValidDate(editedItem.TRNG_DATEISSUED))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										ref="dateValid"
										label="Date Valid"
										:rules= "rulesValid"
										validate-on-blur
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DateValidType"
										v-model="editedItem.TRNG_DATE_VALID"
										@focus="DateValidType = 'date'"
										@blur="DateValidType = (isValidDate(editedItem.TRNG_DATE_VALID))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
						
					</v-card-text>
					
					<v-card-actions>
						<small class="pl-4 font-weight-light">Note: Field with asterisk(*) is required.</small>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="$refs.form.resetValidation(); dialog = false;">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
					</v-card-actions>
				</v-card>
				
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
			<v-dialog
				v-model="dialogDelete"
				max-width="355"
				:persistent="dialogLoading"
			>
			
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Deleting...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
				
				<v-card v-else-if="!dialogLoading">
					<v-card-title class="subtitle-1 warning white--text">CONFIRM DELETE</v-card-title>
					
					<v-card-text class="subtitle-1 font-weight-medium pt-5">Are you sure you want to delete this item?</v-card-text>
					
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDelete = false">NO</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItem()">YES</v-btn>
					</v-card-actions>
				</v-card>
				
			</v-dialog>
			
		</v-card-text>
		
	</v-card>
		
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import { mapGetters } from 'vuex';

var timeoutData				= null;
var timeoutLoading			= null;
var timeoutTableResize		= null;
var timeoutDialogClose		= null;
var timeoutTraining			= null;
var timeoutTrainingCenter	= null;
var timeoutWindowResize		= null;

export default {
	data () {
		return {
			tableTotal			: 0,
			tableOptions		: {	page: 1, itemsPerPage: 10, sortBy: ['TRNG_NAME'], sortDesc: [false] },
			
			hoverNew			: false,
			hoverRefresh		: false,
			
			DateTakenType		: 'text',
			DateFinishedType	: 'text',
			DateIssuedType		: 'text',
			DateValidType		: 'text',
			
			isLoaded			: false,
			
			dialog				: false,
			validForm			: true,
			dialogDelete		: false,
			dialogLoading		: false,
			dataTableLoading	: true,
			
			isSearching			: false,
			searchTraining		: null,
			trainingNoDataText	: 'Start typing to search',
			newTraining			: false,
			trainingOn			: false,
			
			searchingTrainingCenter: false,
			searchTrainingCenter: null,
			trainingCenterNoDataText	: 'Start typing to search',
			newTrainingCenter	: false,
			trainingCenterOn	: false,
			
			trainingCenterOption: [],
			trainingOption		: [],
			training			: [],
			
			tableHeight			: 'undefined',
			
			deleteIndex			: [],
			editedIndex			: -1,
			editedItem: {
				ID				: '',
				TRNG_ID			: '',
				TRNG_NAME		: '',
				TRNG_CENTER_ID	: '',
				TRNG_CENTER		: '',
				TRNG_CERTNUMB	: '',
				TRNG_DATEISSUED	: '',
				TRNG_DATETAKEN	: '',
				TRNG_DATE_FIN	: '',
				TRNG_DATE_VALID	: ''
			},
			defaultItem: {
				ID				: '',
				TRNG_ID			: '',
				TRNG_NAME		: '',
				TRNG_CENTER_ID	: '',
				TRNG_CENTER		: '',
				TRNG_CERTNUMB	: '',
				TRNG_DATEISSUED	: '',
				TRNG_DATETAKEN	: '',
				TRNG_DATE_FIN	: '',
				TRNG_DATE_VALID	: ''
			},
			headers: [
				{ text: 'TRAINING NAME',		value: 'TRNG_NAME',			align: 'start'	},
				{ text: 'DOCUMENT NUMBER',		value: 'TRNG_CERTNUMB',		align: ' d-none',	sortable: false },
				{ text: 'TRAINING CENTER',		value: 'TRNG_CENTER',		align: 'start',		sortable: false },
				{ text: 'DATE TAKEN', 			value: 'TRNG_DATETAKEN',	align: 'center' },
				{ text: 'DATE FINISHED',		value: 'TRNG_DATE_FIN',		align: ' d-none' },
				{ text: 'DATE ISSUED',			value: 'TRNG_DATEISSUED',	align: 'center' },
				{ text: 'DATE OF EXPIRY',		value: 'TRNG_DATE_VALID',	align: ' d-none' },
				{ text: 'STATUS',				value: 'status',			align: 'center',	sortable: false },
				{ text: 'ACTIONS',				value: 'action',			align: 'center',	sortable: false },
			],
			rulesTrainingName: [
				v => !!v || 'Training Name is required',
			],
			rulesTrainingNameNew: [
				v => !!v || 'Training Name is required',
				v => ((!!v && this.stringLength(v) >= 2) || (this.stringLength(v) == 0)) || 'Must be atleast 2 characters',
				v => ((!!v && this.stringLength(v) <= 100) || (this.stringLength(v) == 0)) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ0-9./&,:()-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesTrainingNumber: [
				v => !!v || 'Document Number is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (!!v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
				v => !/[^a-zA-ZñÑ0-9-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesTrainingCenter: [
				v => !!v || 'Training Center is required',
			],
			rulesTrainingCenterNew: [
				v => !!v || 'Training Center is required',
				v => (!!v && this.stringLength(v) >= 3) || 'Must be atleast 3 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ0-9.,-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesTaken: [
				v => !!v || 'Date Taken is required',
				v => this.compareDate(v, this.editedItem.TRNG_DATE_FIN, '=') || 'Date Taken must be equal or less than date finished',
			],
			rulesFinished: [
				v => !!v || 'Date Finished is required',
				v => this.compareDate(this.editedItem.TRNG_DATETAKEN, v, '=') || 'Date Finished must be equal or greater than date taken',
				v => this.compareDate(v, this.editedItem.TRNG_DATEISSUED, '=') || 'Date Finished must be equal or less than date issued',
			],
			rulesIssued: [
				v => !!v || 'Date Issued is required',
				v => this.compareDate(this.editedItem.TRNG_DATE_FIN ,v, '=') || 'Date Issued must be equal or greater than date finished',
			],
			rulesValid: [
				v => (this.compareDate(this.editedItem.TRNG_DATEISSUED ,v)) || 'Date Valid must be greater than date issued',
			],
		}
	},
	methods: {
		getTraining () {//populate data table
			if(this.dataTableLoading && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();

			this.$store.dispatch(CLEAR_ALERT);
			this.dataTableLoading = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
			
			this.$http.get('training', { params:{ options: this.tableOptions } }, { cancelToken: source.token })
			.then((resp) => {
				this.training = resp.data.data;
				this.tableTotal = resp.data.rows;
				this.isLoaded = true;
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getEducation();
				}, 60000);
			})
			.finally(() => {
				this.dataTableLoading = false;
				this.tableResize();
			});
		},
		newItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
			
			setTimeout(() => {
				if(typeof this.$refs.form !== 'undefined')this.$refs.form.resetValidation();
			}, 50);
		},
		editItem: function(item){
			if(this.isValidDate(item.TRNG_DATETAKEN)){
				this.DateTakenType = 'date';
			}else{
				item.TRNG_DATETAKEN = '';
				this.DateTakenType = 'text';
			}
			
			if(this.isValidDate(item.TRNG_DATE_FIN)){
				this.DateFinishedType = 'date';
			}else{
				item.TRNG_DATE_FIN = '';
				this.DateFinishedType = 'text';
			}
			
			if(this.isValidDate(item.TRNG_DATEISSUED)){
				this.DateIssuedType = 'date';
			}else{
				item.TRNG_DATEISSUED = '';
				this.DateIssuedType = 'text';
			}
			
			if(this.isValidDate(item.TRNG_DATE_VALID)){
				this.DateValidType = 'date';
			}else{
				item.TRNG_DATE_VALID = '';
				this.DateValidType = 'text';
			}
			
			if(item.TRNG_ID != 0)this.trainingOption.push( {'TRNG_ID': item.TRNG_ID, 'TRNG_NAME': item.TRNG_NAME} );
			if(item.TRNG_CENTER != 0)this.trainingCenterOption.push( {'ID': item.TRNG_CENTER_ID, 'NAME': item.TRNG_CENTER} );
			
			this.editedIndex = this.training.indexOf(item);
			this.editedItem = Object.assign({}, item);
			
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
		},   
		deleteItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialogLoading = true;
			this.$http.delete('training', {
				params: {
					ID: this.deleteIndex.ID
				}
			}).then(() => {
				const index = this.training.indexOf(this.deleteIndex);
				this.training.splice(index, 1);
				this.tableTotal--;
				this.tableResize();
			})
			.finally(() => {
				this.dialogDelete = false;
				clearTimeout(timeoutLoading);
				timeoutLoading = setTimeout(() => {
					this.dialogLoading = false;
				}, 300);
			});
		},
		saveItem: function(){
			if(this.$refs.form.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.dialogLoading = true;
				
				//add TRNG_NAME value to editedItem object
				if(!this.newTraining)this.editedItem.TRNG_NAME = this.trainingOption.find(({ TRNG_ID }) => TRNG_ID === this.editedItem.TRNG_ID).TRNG_NAME;
				if(!this.newTrainingCenter)this.editedItem.TRNG_CENTER = this.trainingCenterOption.find(({ ID }) => ID === this.editedItem.TRNG_CENTER_ID).NAME;
				
				if(this.editedIndex > -1){//Edit Item
					this.$http.put('training', {
						forms				: this.editedItem,
						newTraining			: this.newTraining,
						newTrainingCenter	: this.newTrainingCenter
					}).then((resp) => {
						if(this.newTraining)this.editedItem.TRNG_ID = resp.data.TID;
						if(this.newTrainingCenter)this.editedItem.TRNG_CENTER_ID = resp.data.CID;
						
						Object.assign(this.training[this.editedIndex], this.editedItem);
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}else{//New Item
					this.$http.post('training', {
						forms				: this.editedItem,
						newTraining			: this.newTraining,
						newTrainingCenter	: this.newTrainingCenter
					}).then((resp) => {
						
						this.editedItem.ID = resp.data.ID;
						
						if(this.newTraining)this.editedItem.TRNG_ID = resp.data.TID;
						if(this.newTrainingCenter)this.editedItem.TRNG_CENTER_ID = resp.data.CID;
						
						this.tableTotal++;
						this.training.unshift(this.editedItem);
						
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}
			}
		},
		tableResize(){
			clearTimeout(timeoutTableResize);
			timeoutTableResize = setTimeout(() => {
				if(typeof this.$refs.dataTableTraining !== 'undefined'){
					const windowWidth = this.$refs.dataTableTraining.$parent.$el.clientWidth;
					const tableWidth = this.$refs.dataTableTraining.$el.children[0].children[0].clientWidth;
					const scrollX = ( tableWidth > windowWidth)? 20 : 0;
					
					const tbodyHeight= this.$refs.dataTableTraining.$el.children[0].children[0].clientHeight;
					this.tableHeight = ( (this.userFormHeight - (40 + 65) ) > tbodyHeight )? tbodyHeight : this.userFormHeight - (40 + 65);
					this.tableHeight += scrollX;
				}
			}, 50);
		},
		resetFormValidation(){
			if(!this.dialogLoading)this.$refs.form.resetValidation();
		},
		itemStatus: function(endDate){
			var dt1 = new Date();
			var dt2 = new Date(endDate);
			return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
		},
		compareDate(d1 , d2, opr){
			if( d1 == '' || d2 == '' || typeof d1 === 'undefined' || typeof d2 === 'undefined' ){
				return true;
			}else{
				var x = new Date(d1);
				var y = new Date(d2);
				return ( opr == '=' )? y >= x : y > x;
			}
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		trimBlur(text){
			if(typeof text !== 'undefined')return text.toUpperCase().replace('ñ', 'Ñ').replace(/ +/g, ' ').trimStart().trim();
		},
		closeDialog () {
			this.dialog = false
			clearTimeout(timeoutDialogClose);
			timeoutDialogClose = setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				
				this.DateTakenType = this.DateFinishedType = 'text';
				this.DateIssuedType = this.DateValidType = 'text';
				
				this.newTraining = this.newTrainingCenter = false;
			}, 50);
		},
		isValidDate(dateString) {
			if(dateString == null)return false;
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'windowSize', 'errorOn', 'errorType', 'errorMessage']),
		dialogTitle () {
			return this.editedIndex === -1 ? 'New Training' : 'Edit Training'
		},
	},
	watch: {
		searchTraining(val){
			if(!this.trainingOn) return
			
			clearTimeout(timeoutTraining);
			
			timeoutTraining = setTimeout(() => {
				this.isSearching = true;
				this.trainingNoDataText = "Searching..."
				this.$http.get('autocomplete/training', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.trainingOption = res.data;
				})
				.finally(() => {
					this.isSearching = false;
					this.trainingNoDataText = (this.trainingOption.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		searchTrainingCenter(val){
			if(!this.trainingCenterOn) return
			
			clearTimeout(timeoutTrainingCenter);
			
			timeoutTrainingCenter = setTimeout(() => {
				this.searchingTrainingCenter = true;
				this.trainingCenterNoDataText = "Searching..."
				this.$http.get('autocomplete/trainingcenter', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.trainingCenterOption = res.data;
				})
				.finally(() => {
					this.searchingTrainingCenter = false;
					this.trainingCenterNoDataText = (this.trainingCenterOption.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		dialog (val) {
			val || this.closeDialog()
		},
		windowSize(newVal, oldVal){
			if( newVal != oldVal ){
				clearTimeout(timeoutWindowResize);
				timeoutWindowResize = setTimeout(() => {
					this.tableResize();
				}, 600)
			}
		},
	},
	created () {
		this.getTraining();
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutLoading);
		clearTimeout(timeoutTableResize);
		clearTimeout(timeoutDialogClose);
		clearTimeout(timeoutTraining);
		clearTimeout(timeoutTrainingCenter);
		clearTimeout(timeoutWindowResize);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>